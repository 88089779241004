import { Component, Input } from '@angular/core';
import { FormControl} from '@angular/forms';

@Component({
    selector: 'file',
    template: `
      <div>
        <div *ngIf="!field.value" class="drop-container dropzone" dropZone (hovered)="toggleHover($event)"
          (dropped)="field.onUpload($event)" [class.hovering]="isHovering">
          <p class="m-0">
            Drag a file here or
            <label class="upload-button">
           
              <input type="file"   (change)="onFileSelect($event.target)" > browse
            </label>
            to upload.
          </p>
        </div>
        <div *ngIf="Images">
         
          <div class="card">
          <input  type="hidden" [(ngModel)]="Images" [id]="field.name" [name]="field.name" [formControl]="form" >
            <img class="card-img-top" [src]="Images" >
          </div>
        </div>
      </div> 
    `,
    styles:[
      `
      .drop-container {
        background: #fff;
        border-radius: 6px;
        height: 150px;
        width: 100%;
        box-shadow: 1px 2px 20px hsla(0,0%,4%,.1);
        display: flex;
        align-items: center;
        justify-content: center;
        border: 2px dashed #c0c4c7;
      }
      p {
        font-size: 16px;
        font-weight: 400;
        color: #c0c4c7; 
      }
      .upload-button {
        display: inline-block;
        border: none;
        outline: none;
        cursor: pointer;
        color: #5754a3;
      }
      .upload-button input {
        display: none;
      }
      .dropzone { 
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column; 
        border-radius: 5px;
        background: white;
        margin: 10px 0;
      }
      .dropzone.hovering {
          border: 2px solid #f16624;
          color: #dadada !important;
      }
      progress::-webkit-progress-value {
        transition: width 0.1s ease;
      }
      `
    ]
})
export class FileComponent {
 
    @Input() field:any = {};
    @Input() form:FormControl;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; }
    constructor() {

    }

    Images

    onFileSelect(input) {
      console.log(input.files);
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = (e: any) => {
          console.log('Got here: ', e.target.result);
         this.Images=  e.target.result;
        }
        reader.readAsDataURL(input.files[0]);
      }
    }

    ngOnChange(){
      console.log(this.field.value);
      // this.field.value.
    }
}