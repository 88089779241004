import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {

  constructor(private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

     const brnId = sessionStorage.getItem('brnId')
      // sessionStorage.getItem('userbrnId')
      // sessionStorage.getItem('sysid')
      // sessionStorage.getItem('syslang')
      // sessionStorage.getItem('usrFullName')
      // sessionStorage.getItem('usrId')
      // sessionStorage.getItem('brnTitle')
      // sessionStorage.getItem('UserbrnTitle')

    if ( brnId != null ) { 
      return true
    }

    else {
      sessionStorage.removeItem('brnId')
      sessionStorage.removeItem('userbrnId')
      sessionStorage.removeItem('sysid')
      sessionStorage.removeItem('syslang')
      sessionStorage.removeItem('usrFullName')
      sessionStorage.removeItem('usrId')
      sessionStorage.removeItem('brnTitle')
      sessionStorage.removeItem('UserbrnTitle')
      this.router.navigate(['/login'])         
      return false
    }
  }
}