<!-- <div id="map"></div> -->
<!-- <div class="col-lg-6 col-sm-12 col-xs-12">

    <mat-form-field appearance="fill" style="  width: 100%;
margin: 0;
margin-right: 10px;">
        <mat-label>{{'branches.type' | translate}}</mat-label>
        <mat-select matNativeControl [(value)]="selectedcompanyType">
            <mat-option *ngFor="let comType of companyType" [value]="comType.SysTypeId">
                {{comType.SysTypeNameAr}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div> -->
<!-- <mat-accordion>             



    <mat-expansion-panel *ngFor="let Main of MenuMain.mainSys" (opened)="panelOpenState = true" (closed)="panelOpenState = false">
        <mat-expansion-panel-header>
            <mat-panel-title>
                {{Main.mainSysTitle}}
            </mat-panel-title>
        </mat-expansion-panel-header>
        <div>
            <m *ngFor="let Brn of  MenuMain.mainBrn">
                <button style="display: block;" *ngIf="Main.mainSysName  == Brn.brnSys;" (click)="getMenu(Brn.brnID)" mat-button>{{Brn.brnTitle}}</button>
            </m>
        </div>

    </mat-expansion-panel>

</mat-accordion>
<div dir="ltr" style="background-color: white;">
    <mat-accordion class="example-headers-align">
        <mat-expansion-panel *ngFor="let Main of MenuMain.mainSys">
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{Main.mainSysTitle}}
                </mat-panel-title>
                <mat-panel-description>

                    <mat-icon>account_circle</mat-icon>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <div class="col-md-4">
                <mat-selection-list [multiple]="false">
                    <mat-list-option *ngFor="let Brn of  MenuMain.mainBrn">
                        <div *ngIf="Main.mainSysName  == Brn.brnSys;" (click)="getMenu(Brn.brnID,Brn.brnTitle)">
                            {{Brn.brnTitle}}
                        </div>

                    </mat-list-option>
                </mat-selection-list>


            </div>
        </mat-expansion-panel>
    </mat-accordion>
</div>
<mat-card class="example-card">

    <mat-card-content>

        <div class="row">
            <div class="col-md-4">
                <mat-card class="example-card">

                    <mat-card-content>
                        <mat-icon align="center" style="font-size: 100px;
                        margin-right: 182px;">account_circle</mat-icon>

                    </mat-card-content>
                    <mat-card-actions style="margin-right: 80px;">
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                    </mat-card-actions>
                </mat-card>
            </div>

            <div class="col-md-4">
                <mat-card class="example-card">
                    <mat-card-header>
                        <div mat-card-avatar class="example-header-image"></div>
                        <mat-card-title>Shiba Inu</mat-card-title>
                        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                        </p>



                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                    </mat-card-actions>
                </mat-card>
            </div>


            <div class="col-md-4">
                <mat-card class="example-card">
                    <mat-card-header>
                        <div mat-card-avatar class="example-header-image"></div>
                        <mat-card-title>Shiba Inu</mat-card-title>
                        <mat-card-subtitle>Dog Breed</mat-card-subtitle>
                    </mat-card-header>
                    <mat-card-content>
                        <p>
                        </p>



                    </mat-card-content>
                    <mat-card-actions>
                        <button mat-button>LIKE</button>
                        <button mat-button>SHARE</button>
                    </mat-card-actions>
                </mat-card>
            </div>
        </div>
    </mat-card-content>

</mat-card>

 -->
<div class="container">
    <div class="row d-none">
        <div class="col-sm-6 col-md-3">
            <div class="card card-stats card-primary card-round">
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <div class="icon-big text-center">
                                <i class="fa fa-home"></i>
                            </div>
                        </div>
                        <div class="col-7 col-stats">
                            <div class="numbers">
                                <p class="card-category">الزائرين</p>
                                <h4 class="card-title">1,294</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="card card-stats card-info card-round">
                <div class="card-body">
                    <div class="row">
                        <div class="col-5">
                            <div class="icon-big text-center">
                                <i class="fa fa-home"></i>
                            </div>
                        </div>
                        <div class="col-7 col-stats">
                            <div class="numbers">
                                <p class="card-category">مشتركين</p>
                                <h4 class="card-title">1303</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-sm-6 col-md-3">
            <div class="card card-stats card-success card-round">
                <div class="card-body ">
                    <div class="row">
                        <div class="col-5">
                            <div class="icon-big text-center">
                                <i class="fa fa-home"></i>
                            </div>
                        </div>
                        <div class="col-7 col-stats">
                            <div class="numbers">
                                <p class="card-category">موردين</p>
                                <h4 class="card-title">1,345</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-sm-6 col-md-3">
            <div class="card card-stats card-secondary card-round">
                <div class="card-body ">
                    <div class="row">
                        <div class="col-5">
                            <div class="icon-big text-center">
                                <i class="fa fa-home"></i>
                            </div>
                        </div>
                        <div class="col-7 col-stats">
                            <div class="numbers">
                                <p class="card-category">مبيعات</p>
                                <h4 class="card-title">576</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-bottom: 10px;">

        <div class="col-sm-12 col-md-12">
            <mat-card>
                <p>{{usrFullName}}</p>
                <p>{{UserbrnTitle}}</p>
            </mat-card>
        </div>
    </div>

    <div class="row" [hidden]="hide_section" style="margin-bottom: 10px;" *ngFor="let Main of MenuMain.mainSys">

        <div class="col-sm-12 col-md-12" *ngIf="Main.mainSysName  ==  sysid ">
            <mat-card>
                <p>{{Main.mainSysTitle}}</p>
                <mat-accordion>
                    <div class="row">

                        <div class="col-sm-12 col-md-4" *ngFor="let Main of MenuMain.mainSys">
                            <m *ngIf="Main.mainSysParent  ==  sysid ">
                                <div class="card card-stats  card-round">
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-5">
                                                <a routerLink="../Branches/{{Main.mainSysName}}">
                                                    <div class="icon-big text-center">
                                                        <i class="fa fa-plus"></i>
                                                    </div>
                                                </a>
                                            </div>
                                            <div class="col-7 col-stats" style="cursor: pointer;">
                                                <a (click)="getMenu()">
                                                    <div class="numbers">
                                                        <p class="card-category">{{Main.mainSysTitle}}</p>

                                                    </div>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </m>
                        </div>
                    </div>
                </mat-accordion>

            </mat-card>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-12" style="margin-top: 10px;" *ngFor="let Main of MenuMain.mainSys">
            <mat-card>
                <p> {{Main.mainSysTitle}}</p>
                <div class="row">
                    <div class="col-sm-12 col-md-4" *ngFor="let Brn of  MenuMain.mainBrn">
                        <N *ngIf="Main.mainSysName  == Brn.brnSys;">
                            <div class="card card-stats  card-round">
                                <div class="card-body">
                                    <div class="row">
                                        <div class="col-12 col-stats" (click)="getMenu(Brn.brnId,Brn.brnTitle)" style="cursor: pointer;">
                                            <div class="numbers">
                                                <p class="card-category">{{Brn.brnTitle}}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </N>
                    </div>
                </div>
            </mat-card>
        </div>

    </div>

</div>