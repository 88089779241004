import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})

export class servicesUrl {
   // public url = 'https://localhost:44325';
  //  public url = 'https://api2.oryxexpress.net:444';
   // public url = 'https://api2.wcrg.org:444';
  //public url = 'https://api2.werig.org:444';
  // public url = 'https://api2.firstsourceco.com:444';  
  // public url = ' https://api2.digitalcitye.com:444';  
   public url = 'https://api2.yg-u.net:444';
  // public url = 'http://api.yg-u.net:444'; 
  //  public url = 'https://api2.technomed-ye.com:444';
  // public url = 'https://api2.alshamelah-ye.com:444';
  // public url = 'https://api2.yuniv.net:444';
 //  public url = 'https://api2.spectragp.com:444';
 // public url = 'https://api2.siametricint.com:444';
//  public url = 'https://usermanual.api2.newtouch.ws:444';
  // public url = 'https://api2.newtouch.dev:444';
  // public url = 'https://Oceanweb.api2.prixerp.com:444';
  // public url = 'https://api2.glintaps.com:444';
  // public url = 'https://api2.yathreb-ye.com:444';
  // public url = 'https://api2.gic-edu.net:444';
  // public url = 'https://api2.avisiongp.com:444';
  // public url = 'http://oceanweb.api2.prixerp.com';
 

}