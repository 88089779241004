import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { BranchesComponent } from './components/branches/branches.component';
import { CompanyComponent } from './components/company/company.component';
 import { dashboardComponent } from './components/doshbord/dashboard.component';
import { MenuComponent } from './components/menu/menu.component';
import { OurTeamComponent } from './components/our-team/our-team.component';
import { UsersComponent } from './components/users/users.component';

const routes: Routes = [
  { 
    path: '', loadChildren :() => import('./components/login/login.module').then(m => m.LoginModule)
  },
  { 
    path: 'login', loadChildren :() => import('./components/login/login.module').then(m => m.LoginModule)
  },
  {
    path: '', component: MenuComponent, canActivate: [AuthGuard],
    children:
      [
        // { 
        //    path: 'dashboard', loadChildren :() => import('./components/doshbord/dashboard.module').then(m => m.dashboardModule)
        //  },
        { path: 'dashboard', component: dashboardComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
        { path: 'Branches', component: BranchesComponent, canActivate: [AuthGuard] },
        { path: 'Branches/:id', component: BranchesComponent, canActivate: [AuthGuard] },
        { path: 'company', component: CompanyComponent, canActivate: [AuthGuard] },
        { path: 'OurTeam', component: OurTeamComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
        { path: 'Page/:name', component: OurTeamComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
        { path: 'Users', component: UsersComponent, canActivate: [AuthGuard], runGuardsAndResolvers: 'always' },
      ]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }