import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { IourTeam, IourTeamStatus } from 'src/app/components/our-team/model';
import { CryptService } from '../crypt.service';

import { servicesUrl } from '../servicesUrl';

@Injectable({
  providedIn: 'root'
})
export class OurTeamService {

  constructor( private c : CryptService, private http: HttpClient, private urlApi: servicesUrl) { }

  public imageUrl(): string {
    return this.urlApi.url + '/images/post/'
  }

  public GetMenu(check: string,FbrnId: string, lang: string) {
    const formData = new FormData();
    formData.append('check', check);
     formData.append('FbrnId',this.c.Decrypt(sessionStorage.getItem('brnId')));
     formData.append('Lang', lang);
    return this.http.post(this.urlApi.url + '/our_team/getMenu', formData).pipe();
  }

  public sysProperty(check: any,MType:string,Lang:string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('MType', MType);
    formData.append('Lang', Lang);
    return this.http.post(this.urlApi.url + '/our_team/sysProperty', formData).pipe();
  }

  public GetPostMetaVal(check: any,postId:string,MType:string,Lang:string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('MType', MType);
    formData.append('Lang', Lang);
    formData.append('postId', postId);
    return this.http.post(this.urlApi.url + '/our_team/GetPostMetaVal', formData).pipe();
  }

  public OrderPost(check: string,order:string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('order', order);
    return this.http.post(this.urlApi.url + '/our_team/OrderPost', formData).pipe();
  }

  public MainSys(check: string,FbrnId: string, lang: string) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('FbrnId',this.c.Decrypt(sessionStorage.getItem('brnId')));
     formData.append('Lang', lang);
    return this.http.post(this.urlApi.url + '/our_team/getMainSys', formData).pipe();
  }

  public SettingScreen(MType: string,LANG:string) {
    const formData = new FormData();
    formData.append('MType', MType);
    formData.append('LANG',LANG)
    return this.http.post(this.urlApi.url + '/our_team/SettingScreen', formData).pipe();
  }

  public OurTeamStatus(type,lang): Observable<IourTeamStatus[]> {
    return this.http.get<IourTeamStatus[]>(this.urlApi.url + '/our_team' + '/OurTeamStatus/'+type+'/'+lang).pipe();
  }

  public GetOurTeamWhereSection(id, lang): Observable<IourTeam[]> {
    return this.http.get<IourTeam[]>(this.urlApi.url + '/our_team/' + id + '/' + lang).pipe();
  }

  public GetOurTeamLang(id): Observable<IourTeam[]> {
    return this.http.get<IourTeam[]>(this.urlApi.url + '/our_team/lang/').pipe();
  }

  public GetsectionOurTeam(lang,brn,type): Observable<IourTeam[]> {
    return this.http.get<IourTeam[]>(this.urlApi.url + '/our_team/section/' + lang +'/'+brn+'/'+type).pipe();
  }
  
  public OurTeamPost(check, TeamId,MType, ParentId, TeamName,PostTitle, TeamIsHome, TeamDsc,TeamDetails,TeamImage, FbrnId, FusrId, Fstate, LangId,forms,file,checkFile) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('PostId', TeamId);
    formData.append('MTYPE', MType);
    formData.append('ParentId', ParentId);
    formData.append('PostName', TeamName);
    formData.append('PostTitle', PostTitle);
    formData.append('PostIsHome', TeamIsHome);
    formData.append('PostDsc', TeamDsc);
    formData.append('PostDetails', TeamDetails);
    formData.append('PostImage', TeamImage);
    formData.append('FbrnId', FbrnId);
    formData.append('FusrId', FusrId);
    formData.append('Fstate', Fstate);
    formData.append('LangId', LangId);
    formData.append('forms',forms)
    formData.append('file',file)
    formData.append('checkFile',checkFile)
    return this.http.post(this.urlApi.url + '/our_team/' + 'OurTeamPost', formData).pipe();
  }

  public deleteProd(check, TeamId, langId,namefile) {
    const formData = new FormData();
    formData.append('check', check);
    formData.append('PostId', TeamId);
    formData.append('LangId', langId);
    formData.append('namefile', namefile);
    return this.http.post(this.urlApi.url + '/our_team/' + 'OurTeamPost', formData).pipe();
  }

  public viewProdPaging(pageIndex, ddl,brn,lang,type) {
    const formData = new FormData();
    formData.append('pageIndex', pageIndex);
    formData.append('ddl', ddl);
    formData.append('Brn', brn);
    formData.append('LANG', lang);
    formData.append('TYPE', type);
    return this.http.post(this.urlApi.url + '/our_team/pagging', formData).pipe();
  }
}
