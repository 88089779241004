<mat-sidenav-container class="sidenav-container">
    <mat-sidenav style="    background: #263238;border: 0;" #drawer class="sidenav" fixedInViewport [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'" [mode]="(isHandset$ | async) ? 'over' : 'side'" [opened]="(isHandset$ | async) === false">
        <mat-toolbar class="name_company">
            <img style="height: 30px;" src="assets/images/nt.png" />
            <span style="margin-right: 5px;">NewTouch</span>
        </mat-toolbar>
        <mat-nav-list>
            <mat-accordion>
                <M *ngFor="let P of MenuMain.mainMenu">
                    <a (click)="d(P.routeComponent)" *ngIf="P.isLeave && P.isRoot" style=" color: rgba(163,175,183,.9);" mat-list-item>
                        <mat-icon style="font-size: 22px;line-height: 28px;">{{P.menuIcon}}</mat-icon>
                        <span>{{P.menueParentTitle}}</span>

                    </a>
                </M>

            </mat-accordion>
            <mat-accordion>

                <mat-expansion-panel *ngFor="let P of MenuMain.mainMenu" style="background: #263238;line-height: 27px;" (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                    <mat-expansion-panel-header *ngIf="!P.isLeave">

                        <mat-panel-title>

                            <mat-icon style=" color: rgba(163,175,183,.9);padding: 2px;">{{P.menuIcon}}</mat-icon>
                            <span style=" color: rgba(163,175,183,.9);">{{P.menueParentTitle}}</span>

                        </mat-panel-title>

                    </mat-expansion-panel-header>

                    <M *ngFor="let c of MenuMain.itemMenu">
                        <a *ngIf="P.menuParentId  == c.menuParentId;" routerLink="Page/{{c.menuItemId}}" style=" color: rgba(163,175,183,.9);" mat-list-item>

                            <mat-icon style="font-size: 22px;line-height: 28px;">{{c.menuIcon}}</mat-icon>
                            <span>{{c.menuItemTitle}}</span>

                        </a>
                    </M>

                </mat-expansion-panel>

            </mat-accordion>

        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content>

        <mat-toolbar>
            <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon" type="button" aria-label="Toggle sidenav" mat-icon-button (click)="drawer.toggle()">
                <mat-icon>menu</mat-icon>
            </button>
            <span>{{title}}</span>
            <span class="example-spacer"></span>
            <div style="position: absolute;
      left: 0;
      margin-left: 17px;">
                <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">

                    <mat-icon>more_vert</mat-icon>

                </button>
                <mat-menu #menu="matMenu">

                    <button mat-menu-item disabled>
                        <mat-icon>info</mat-icon>
                        <span>عن النظام</span>
                    </button>
                    <button mat-menu-item disabled>
                        <mat-icon>help</mat-icon>
                        <span>مساعدة</span>
                    </button>
                    <button mat-menu-item routerLink="Users">
                        <mat-icon>person_add </mat-icon>
                        <span>اضافة مستخدم</span>
                    </button>
                    <button mat-menu-item (click)="openDialog()">
                        <mat-icon>change_circle</mat-icon>
                        <span>تغيير كلمة المرور</span>
                    </button>
                    <mat-divider></mat-divider>
                    <button (click)="logout()" mat-menu-item>
                        <mat-icon>logout</mat-icon>
                        <span>تسجيل خروج</span>
                    </button>
                </mat-menu>
            </div>
            <div style="position: absolute;
      left: 0;
      margin-left: 60px;">
                <button mat-icon-button [matMenuTriggerFor]="translate" aria-label="Example icon-button with a menu">

                    <mat-icon>g_translate</mat-icon>

                </button>
                <mat-menu #translate="matMenu">


                    <button (click)="changeLangage('Ar')" mat-menu-item>
                        <span>عربى</span>
                    </button>

                    <button (click)="changeLangage('En')" mat-menu-item>
                        <span>English</span>
                    </button>
                </mat-menu>
            </div>
        </mat-toolbar>

        <main class="panleNewTouch">

            <router-outlet></router-outlet>

        </main>




        <footer class="site-footer">

            <div class="site-footer-legal"> <a target="_blank" href="https://newtouch.dev/">NewTouch Smart Technology Solutions </a>Content Management System </div>

            <div class="site-footer-right">
                نيوتاتش للحلول التقنية الذكية -- نظام إدارة المحتوى
            </div>
            <p style="text-align: center;"> © 2016-2021</p>
        </footer>

    </mat-sidenav-content>

</mat-sidenav-container>