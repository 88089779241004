import { Component, enableProdMode, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CryptService } from 'src/app/services/crypt.service';
import { OurTeamService } from 'src/app/services/our-team/our-team.service';
import { MenuComponent } from '../menu/menu.component';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class dashboardComponent implements OnInit {
 

  companyType
   BrnId :string 
   sysid :string 
   hide_section:boolean = false
   MenuMain: any = []
   usrFullName:string
   UserbrnTitle:string

  constructor(private c : CryptService ,private m:OurTeamService,public a : MenuComponent,private menu:MenuComponent,private _Activatedroute: ActivatedRoute)
   { 

   
    this._Activatedroute.paramMap.subscribe(params => {
      this.ngOnInit();
      window.scroll(0, 0);
    });
   }

  ngOnInit(): void { 

    this.usrFullName = this.c.Decrypt( sessionStorage.getItem('usrFullName'))
    this.UserbrnTitle = this.c.Decrypt(sessionStorage.getItem('UserbrnTitle'))
    this.BrnId = this.c.Decrypt(sessionStorage.getItem('brnId'))
    this.sysid = this.c.Decrypt(sessionStorage.getItem('sysid'))
    this.menu.setHeader();

    this.m.MainSys('','',this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data=>{
      this.MenuMain = data
      // console.log(data)
      if(this.MenuMain['mainSys'].length == 1)
      this.hide_section = true
    })
  }

  getMenu(brn,brnTitle):void { 
 sessionStorage.setItem('brnId',this.c.Encrypt(brn));
    sessionStorage.setItem('brnTitle', this.c.Encrypt(brnTitle));
    this.a.getM(this.c.Decrypt(sessionStorage.getItem('syslang')),brn)

    this.menu.setHeader();
  
    this.m.MainSys('','',this.c.Decrypt(sessionStorage.getItem('syslang'))).subscribe(data=>{
      this.MenuMain = data
      // console.log(data)
      if(this.MenuMain['mainSys'].length == 1)
      this.hide_section = true
    })

  }

  }
