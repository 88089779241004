import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';
import { servicesFontsIcons } from 'src/app/services/servicesFontsIcons';

@Component({
    selector: 'Icons',
    template: `
        <input   [(ngModel)]="NameIcon"  [attr.type]="field.type" class="form-control d-none"  [id]="field.name" [name]="field.name" [formControl]="form"> 
         
<ng-container>
<div [hidden]="showIcons" id="exampleModalLive" class="modal fade show" tabindex="-1" role="dialog" aria-labelledby="exampleModalLiveLabel" style="padding-right: 17px; display:block;">
    <div class="modal-dialog" role="document">
        <div class="modal-content" style="width: 100%;">
            <div class="modal-header">
                <h3 class="modal-title" id="exampleModalLiveLabel">اختيار ايقونة</h3>
                <button (click)="closeIcon()" class="close" data-dismiss="modal" aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
            </div>
            <div class="modal-body">

                <div style="height: 300px;
            overflow-y: auto;
            margin:  auto;
            padding: 2rem;
            " *ngFor="let Icon of fontIcons">
                    <p style="text-align: center;background: #3e8ef7;
              color: #fff;
              padding: 5px;">{{Icon.type}}</p>
                    <div class="row">

                        <div style="text-align: center;" *ngFor="let Icons of Icon.fonts" class="col-lg-3 col-md-2 col-sm-2 col-xs-2">
                            <i (click)="getNameIcon(Icons)" style="font-size: 35px; padding: 4px; cursor: pointer;text-align: center;" [class]="Icons"></i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="closeIcon()" data-dismiss="modal">الغاء</button>
            </div>
        </div>
    </div>
</div>

</ng-container>

<div class="row">
<div class="col-lg-12 col-sm-12 col-xs-12" style="margin-top: 10px;text-align: center;">

<button (click)="showIcon()" style="margin-bottom: 10px;" mat-raised-button color="primary">
اختيار ايقونة
<i style="    font-size: 25px;
margin-top: 5px;" [class]="NameIcon"></i>
</button>
</div>

</div>      
    `
})
export class IconsComponent {
    @Input() field: any = {};
    @Input() form: FormControl;
    get isValid() { return this.form.valid; }
    get isDirty() { return this.form.dirty; }


    showIcons: boolean = true
    NameIcon: string = ''
    fontIcons: any = null

    closeIcon() {
        this.showIcons = true
    }
    showIcon() {
        this.showIcons = false
    }
    getNameIcon(Icons) {
        this.NameIcon = Icons
        this.showIcons = true
    }
    constructor(private fonts: servicesFontsIcons) { this.fontIcons = this.fonts.icon }
}